import React, { useRef } from "react";
import useState from "react-usestateref";
import { Link, useNavigate } from "react-router-dom";
import { IoMdCall } from "react-icons/io";
import ANTIWarTNimg from "../assets/ANTIWarTNimg.webp";
import REXITNimg from "../assets/REXITNimg.webp";
import TTTTNimg from "../assets/TTTTNimg.webp";
import HupBetTNimg from "../assets/HupBetTNimg.webp";
import icGAMEtNimg from "../assets/icGAMEtNimg.webp";
import WXDLTnimg from "../assets/WXDLTnimg.webp";
import VoltrixICOTNimg from "../assets/VoltrixICOTNimg.webp";
import VoltrixExchangeimg from "../assets/VoltrixExchangeimg.webp";
import BITBuyerxTNimg from "../assets/BITBuyerxTNimg.webp";
import Teleminigameimg from "../assets/Teleminigameimg.webp";
import BitgetAppTnimg from "../assets/BitgetAppTnimg.webp";
import FidexApptnimg from "../assets/FidexApptnimg.webp";
import OwnBlockchainimg from "../assets/OwnBlockchainimg.webp";
import "react-phone-input-2/lib/style.css";
import keedeximg from "../assets/keedeximg.webp";
import Yarlooimg from "../assets/Yarlooimg.webp";
import nftimg from "../assets/nftimg.webp";
import nftimgimg from "../assets/nftimg2.webp";
import nft3img from "../assets/nft3img.webp";
import LoudNFtTnimg from "../assets/LoudNFtTnimg.webp";
import paymentgatewaytnimg from "../assets/paymentgatewaytnimg.webp";
import TrustPeapptnimg from "../assets/TrustPeapptnimg.webp";
import VoltriAppnimg from "../assets/VoltriAppnimg.webp";
import FidexTNimg from "../assets/FidexTNimg.webp";
import ZurumiTNimg from "../assets/ZurumiTNimg.webp";
import BabyWalrumNimg from "../assets/BabyWalrumNimg.webp";
import MevBotTnimg from "../assets/MevBotTnimg.webp";
import Enzothumbnailimg from "../assets/Enzothumbnailimg.webp";
import DahaulBottnimg from "../assets/DahaulBottnimg.webp";
import FlashLoanTnimg from "../assets/FlashLoanTnimg.webp";
import MarketMakingBotTimg from "../assets/MarketMakingBotTimg.webp";
import WYScaleTnimg from "../assets/WYScaleTnimg.webp";
import scknimg from "../assets/scknimg.webp";
import MitcomeThumbnailimg from "../assets/MitcomeThumbnailimg.webp";
import { FaLinkedinIn } from "react-icons/fa";
import { sendcontactpageContacts } from "../config/apiCall";
import httpService from "../config/httpCall";
import { FaInstagramSquare } from "react-icons/fa";
import Logo from "../assets/logo.png";
import { AppBar, Toolbar, Typography } from "@material-ui/core";
import { TbWorld } from "react-icons/tb";
import PhoneInput from "react-phone-input-2";
import "./style.css";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NewHome = () => {
  const [activeSection, setActiveSection] = useState("about");
  const [value, setValue] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newformmediumErr, setnewformmediumErr] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);

  const [message, setMessage] = useState("");
  const [newformuserNameErr, setnewformuserNameErr, newformuserNameErrref] =
    useState(false);
  const [selectedPlatform2, setSelectedPlatform2] = useState("whatsApp");
  const [newformmobileErr, setnewformmobileErr, newformmobileErrref] =
    useState(false);
  const [
    newformmediumValueErr,
    setnewformmediumValueErr,
    newformmediumValueErrref,
  ] = useState(false);
  const [newformmessageErr, setnewformmessageErr, newformmessageErrref] =
    useState(false);
  const [newformmobilecodeErr, setnewformmobilecodeErr] = useState(false);
  const [NewFormMobileValue, setNewFormMobileValue] = useState();
  const [platformInput, setPlatformInput, platformInputref] = useState("");

  const [validationErr, setvalidationErr, validationErrref] = useState("");
  const [NewFormNMobileCode, setNewFormNMobileCode] = useState();
  const [NewFormcountry, setNewFormcountry] = useState();
  const [menuopen, setMenuopn] = useState(false);
  const [activeSections, setActiveSections] = useState({
    section1: true,
    section2: false,
    section3: false,
    section4: false,
  });
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const NewFormValue = {
    newformmediumValue: "",
    newformmedium: "whatsApp",
    newformmessage: "",
    newformmobile: "",
    newformuserName: "",
    newformmobilecode: "",
  };
  const [newValue, setnewValue, newValueref] = useState(NewFormValue);

  const { newformuserName, newformmessage } = newValue;

  const [errors, setErrors] = useState({});

  const refs = {
    "Meme Coin": useRef(null),
    ICO: useRef(null),
    Gaming: useRef(null),
    Bot: useRef(null),
    "Mobile App": useRef(null),
    MLM: useRef(null),
    "Own BlockChain": useRef(null),
    "Decentralized Exchange & Defi": useRef(null),
    NFT: useRef(null),
    "Cryto Exchange": useRef(null),
    "Crypto Payment": useRef(null),
  };

  const scrollToImage = (val) => {
    if (refs[val]) {
      refs[val]?.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const cancelpopup = () => {};

  const handleActiveSection = (section) => {
    setActiveSection(section);
    setMenuopn(false);
    setActiveSections((prevState) => ({
      section1: section === "about",
      section2: section === "services",
      section3: section === "projects",
      section4: section === "contact",
    }));
  };

  const validate = async (formData) => {
    try {
      var error = {};
      const countryCodeLength = formData.newformmobilecode.length;
      const actualMobileLength =
        formData.newformmobile.length - countryCodeLength;

      // Check for empty username
      if (formData.newformuserName.trim() === "") {
        setnewformuserNameErr(true);
        error.newformuserName = "Must enter the field";
        setvalidationErr(error);
        return;
      } else if (formData.newformuserName.length < 3) {
        setnewformuserNameErr(true);
        error.newformuserName = "Invalid username, Must be 3-50 characters";
        setvalidationErr(error);
        return;
      }

      // Check for empty mobile
      else if (formData.newformmobile.trim() === "") {
        setnewformuserNameErr(false);
        setnewformmobileErr(true);
        error.newformmobile = "Must enter the field";
        setvalidationErr(error);
        return;
      } else if (actualMobileLength < 7) {
        setnewformuserNameErr(false);
        setnewformmobileErr(true);
        error.newformmobile =
          "Invalid number, Must be at least 7 digits excluding country code";
        setvalidationErr(error);
        return;
      }

      // Email Validation
      else if (
        selectedPlatform2 === "email" &&
        (platformInputref?.current.trim() === "" ||
          platformInputref?.current.startsWith(" ")) // Check for leading space
      ) {
        setnewformmobileErr(false);
        setnewformmediumValueErr(true);
        error.newformmediumValue = "Email can't start with a space";
        setvalidationErr(error);
        return;
      } else if (
        selectedPlatform2 === "email" &&
        !/^[a-zA-Z0-9.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(
          platformInputref?.current
        )
      ) {
        setnewformmediumValueErr(true);
        error.newformmediumValue = "Invalid email format";
        setvalidationErr(error);
        return;
      }

      // Other platform validation
      else if (["whatsApp", "skype", "telegram"].includes(selectedPlatform2)) {
        if (platformInputref?.current.trim() === "") {
          setnewformmediumValueErr(true);
          error.newformmediumValue = "Must enter the field";
          setvalidationErr(error);
          return;
        } else if (
          selectedPlatform2 === "whatsApp" &&
          platformInputref?.current.length < 7
        ) {
          setnewformmediumValueErr(true);
          error.newformmediumValue = `Whatsapp number must be at least 7 digits`;
          setvalidationErr(error);
          return;
        } else if (
          selectedPlatform2 === "skype" &&
          platformInputref?.current.length < 5
        ) {
          setnewformmediumValueErr(true);
          error.newformmediumValue = `Skype ID / Number must be at least 5 characters`;
          setvalidationErr(error);
          return;
        } else if (platformInputref?.current.length < 5) {
          setnewformmediumValueErr(true);
          error.newformmediumValue = `Telegram ID / Number must be at least 5 characters`;
          setvalidationErr(error);
          return;
        } else {
          setnewformuserNameErr(false);
          setnewformmediumValueErr(false);
        }
      }

      // Message Validation
      if (formData.newformmessage.trim() === "") {
        setnewformmediumValueErr(false);
        setnewformmobileErr(false);
        setnewformmessageErr(true);
        error.newformmessage = "Must enter the field";
        setvalidationErr(error);
      } else if (formData.newformmessage.length < 3) {
        setnewformmessageErr(true);
        error.newformmessage = "Message must contain at least 3 characters";
        setvalidationErr(error);
      } else if (formData.newformmessage.length > 250) {
        setnewformmessageErr(true);
        error.newformmessage = "Message can't exceed 250 characters";
        setvalidationErr(error);
      } else {
        setnewformmessageErr(false);
      }

      setvalidationErr(error);
    } catch (error) {
      // console.error(error);
    }
  };

  const sendServiceContact = async (e) => {
    try {
      e.preventDefault();
      if (
        newValueref.current.newformuserName !== "" &&
        newformuserNameErrref.current === false &&
        newformmessageErrref.current === false &&
        newformmobileErrref.current === false &&
        newformmediumValueErrref.current == false &&
        newformmobilecodeErr === false &&
        newValueref.current.newformmessage != ""
      ) {
        // setbuttonLoader(true);
        newValueref.current.newformmedium = selectedPlatform2;
        newValueref.current.newformmediumValue = platformInputref?.current;

        var obj = {
          apiUrl: httpService.sendServiceContactDetails,
          payload: newValueref.current,
        };
        var messageSent = await sendcontactpageContacts(obj);
        setbuttonLoader(false);
        newValueref.current.newformuserName = "";
        newValueref.current.newformmessage = "";
        newValueref.current.newformmobile = "";
        newValueref.current.newformmobilecode = "";
        setNewFormMobileValue("");
        setPlatformInput("");
        setNewFormNMobileCode("");
        setNewFormcountry();
        handleNewFormMobile("", "", "", "");
        if (messageSent.status == true) {
          toast.success(messageSent.Message);
          cancelpopup();
        } else {
          toast.error(messageSent.Message);
          cancelpopup();
        }
      } else {
        validate(newValueref.current);
      }
    } catch (err) {}
  };

  const handleNewFormMobile = (value, countryObj, e, formattedValue) => {
    setNewFormMobileValue(value);
    setNewFormNMobileCode(countryObj.dialCode);
    setNewFormcountry(countryObj.name);
    newValueref.current["newformmobilecode"] = countryObj.dialCode;
    newValueref.current["newformmobile"] = value;
    newValueref.current["NewFormcountry"] = countryObj.name;
    setSelectedPlatform2("whatsApp");
    setPlatformInput("");
    validate(newValueref.current);
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (value.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "This field cannot be empty or start with spaces.",
      }));
    }
  };

  const handlePlatformSelect = (e, platform) => {
    e.preventDefault();
    setSelectedPlatform2(platform);
    setPlatformInput("");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Remove leading spaces for all fields
    const trimmedValue = value.replace(/^\s+/, "");

    setFormData((prevData) => ({
      ...prevData,
      [name]: trimmedValue,
    }));

    // Clear the error if the input becomes valid
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };

      // Validation logic for each field
      if (name === "name" && trimmedValue.trim() !== "") {
        delete updatedErrors.name;
      }
      if (name === "email") {
        // Ensure no leading space and valid email format
        if (trimmedValue.trim() !== "" && /\S+@\S+\.\S+/.test(trimmedValue)) {
          delete updatedErrors.email;
        } else if (value.startsWith(" ")) {
          updatedErrors.email = "";
        }
      }
      if (name === "phone" && /^\d{7}$/.test(trimmedValue)) {
        delete updatedErrors.phone;
      }
      if (name === "message" && trimmedValue.trim().length >= 3) {
        delete updatedErrors.message;
      }

      return updatedErrors;
    });
  };

  const handlePlatformInputChange = (e) => {
    const value = e.target.value;
    if (
      (e.key === " " && e.target.selectionStart === 0) ||
      e.target.value.startsWith(" ")
    ) {
      e.target.value = e.target.value.trimStart(); // Trim starting spaces
    }

    setPlatformInput(value);
    if (value && value.length > 0) {
      setnewformmediumErr(false);
      setvalidationErr((prev) => ({ ...prev, newformmedium: "" }));
    } else {
      setnewformmediumErr(true);
      setvalidationErr((prev) => ({
        ...prev,
        newformmedium: "Must enter the field",
      }));
    }
    // newValueref.current.newformmessage = "";
    validate(newValueref?.current);
    if (
      selectedPlatform2 === "email" &&
      !/^[^\s@]+@[^\s@]+\.(com)$/.test(platformInputref?.current)
    ) {
      validate(newValueref?.current);
    }
  };

  const handleKeyPress = (e, maxLength, type) => {
    const allowedKeys = [
      "Backspace",
      "Delete",
      "Tab",
      "Escape",
      "Enter",
      "ArrowLeft",
      "ArrowRight",
    ];

    // Prevent exceeding maxLength for all input types
    if (e.target.value.length >= maxLength && !allowedKeys.includes(e.key)) {
      e.preventDefault();
      return;
    }

    if (type === "email") {
      const validEmailChars = /^[a-zA-Z0-9@._-]+$/;
      if (!validEmailChars.test(e.key) && !allowedKeys.includes(e.key)) {
        e.preventDefault();
      }
      return;
    }

    if (type === "number") {
      const cursorPosition = e.target.selectionStart;

      // Prevent spaces at the start
      if (e.key === " " && cursorPosition === 0) {
        e.preventDefault();
        return;
      }

      // Allow only digits, a leading plus sign, and spaces
      if (
        !/[0-9 ]/.test(e.key) &&
        !(e.key === "+" && cursorPosition === 0) &&
        !allowedKeys.includes(e.key)
      ) {
        e.preventDefault();
      }
      return;
    }

    if (type === "text") {
      const cursorPosition = e.target.selectionStart;

      // Prevent spaces at the start
      if (e.key === " " && cursorPosition === 0) {
        e.preventDefault();
        return;
      }

      // Allow editing within maxLength
      if (e.target.value.length < maxLength || allowedKeys.includes(e.key)) {
        return;
      }

      e.preventDefault();
    }
  };

  const handleNewFormChange = async (e) => {
    try {
      if (
        (e.key === " " && e.target.selectionStart === 0) ||
        e.target.value.startsWith(" ")
      ) {
        e.target.value = e.target.value.trimStart(); // Trim starting spaces
      }

      const { name, value } = e.target;
      // Update the form data state
      const updatedFormData = { ...newValue, [name]: value };
      setnewValue(updatedFormData);

      // Validate the updated form data
      await validate(updatedFormData);

      // Platform-specific validation
      if (selectedPlatform2 && !platformInputref?.current) {
        setnewformmediumValueErr(true);
      } else {
        setnewformmediumValueErr(false);
      }

      // Example: Custom validation for specific fields
      if (name === "newformmessage" && !value.trim()) {
        setnewformmessageErr(true);
      } else if (name === "newformmessage") {
        setnewformmessageErr(false);
      }
    } catch (error) {
      console.error("Error during form validation:", error);
    }
  };

  const menuopenClose = () => {
    setMenuopn(true);
  };

  const validateForm = () => {
    const newErrors = {};
    const { name, email, phone, message } = formData;

    if (!name.trim()) {
      newErrors.name = "Name is required.";
    }
    if (!email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email address is invalid.";
    }
    if (!phone.trim()) {
      newErrors.phone = "Phone number is required.";
    } else if (!/^\d{7}$/.test(phone)) {
      newErrors.phone = "Phone number must be 7 digits.";
    }
    if (!message.trim()) {
      newErrors.message = "Message must be at least 3 characters long.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Proceed with form submission (e.g., send data to server)
      console.log("Form submitted:", formData);
      // Reset form
      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
    }
  };

  const handleCloseMet = () => {
    setMenuopn(false);
  };

  const navigate = useNavigate();

  const handleAboutLogoClick = () => {
    localStorage.setItem("cameFromAbout", "true");
    navigate("/");
  };

  return (
    <>
      <div
        className={`main-bg 
          ${activeSections.section1 && "about-bg"} 
          ${activeSections.section2 && "service-bg"}
          ${activeSections.section3 && "projects-bg"}
          ${activeSections.section4 && "contact-bg"}
          `}
      >
        <div
          data-bs-spy="scroll"
          data-bs-target=".navbar"
          data-bs-offset="50"
          className=""
        >
          <section
            className={`main-header ${
              menuopen === true ? "menuopenzindex" : "menuclosezindex"
            }`}
          >
            <header className="scrolled header-wrapper">
              <AppBar position="static" className="header_secondsec">
                <Toolbar className="container-fluid p-0 w-100 justify-content-between">
                  <Typography variant="h6">
                    <div className="d-flex" onClick={handleAboutLogoClick}>
                      <Link className="logo-wrapper">
                        <img src={Logo} className="img-fluid" alt="logo" />
                      </Link>
                    </div>
                  </Typography>
                  <div className="rightside_menu">
                    <div>
                      <>
                        <button
                          className="header-btn"
                          onClick={() =>
                            window.open(
                              "https://beleaftechnologies.com/",
                              "_blank"
                            )
                          }
                        >
                          <span className="web_icon">
                            <TbWorld />
                          </span>
                          <span>Website</span>
                        </button>
                      </>
                    </div>
                    <div className="menu_img" onClick={menuopenClose}>
                      {/* <img src={Menuimg} alt="...Loading" /> */}
                      <span className="hamburger-menu">
                        <i class="fa-solid fa-bars"></i>
                      </span>
                    </div>
                  </div>
                </Toolbar>
              </AppBar>
            </header>
          </section>

          {/* Sections */}
          <div>
            <div className="header-wrapper section-wrapper">
              <div
                id="about"
                // className="container-fluid  section_main"
                className={`container-fluid section-one ${
                  activeSections.section1 ? "active_class" : "nonactive_class"
                }`}
              >
                <div>
                  <div className="row">
                    <div className="col-lg-6 ">
                      <div className="getting_card_content">
                        <span
                          className="getcard_sub_subhead padding-right"
                          data-aos="fade-left"
                        >
                          Who we are.
                        </span>
                        <h1 data-aos="fade-left">About beleaf</h1>
                        <p data-aos="fade-up" data-aos-delay="4000">
                          Beleaf Technologies is a leading blockchain
                          development service provider, specializing in creating
                          advanced blockchain solutions for businesses of all
                          sizes.
                        </p>
                        <p data-aos="fade-up" data-aos-delay="4000">
                          Our team of experts is passionate about blockchain
                          technology and is dedicated to helping you support its
                          potential to the fullest. With years of experience and
                          a deep understanding of the blockchain, we are your
                          trusted partner in directing the complexities of this
                          extensive technology.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6"></div>
                  </div>

                  <div className="row get-in-touch-row">
                    <div className="col-lg-5">
                      <div
                        className="about-get-in-touch"
                        data-toggle="modal"
                        data-target="#myModal"
                        // data-aos="fade-left"
                      >
                        <p>Get in Touch</p>
                        <span>
                          <IoMdCall />
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-7 aboutus_header">
                      <div className="clients-row">
                        <div className="col-lg-2">
                          <h2 className="clients-num">100+</h2>
                          <span className="border_services"></span>
                          <p className="happyclient_tag">
                            Happy <br /> clients
                          </p>
                        </div>
                        <div className="col-lg-2">
                          <h2 className="clients-num">100+</h2>
                          <span className="border_services"></span>
                          <p className="happyclient_tag">
                            Successful <br /> projects
                          </p>
                        </div>
                        <div className="col-lg-2">
                          <h2 className="clients-num">100+</h2>
                          <span className="border_services"></span>
                          <p className="happyclient_tag">
                            Countries <br /> served
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="services"
                className={`container-fluid section-two ${
                  activeSections.section2 ? "active_class" : "nonactive_class"
                }`}
              >
                <div>
                  <div className="row content-main">
                    <div className="col-lg-5  col-md-4">
                      <div className="getting_card_content">
                        <span className="getcard_sub_subhead padding-right">
                          What we can
                        </span>
                        <h1>Services</h1>
                        <p>
                          We’re here to support all your blockchain development
                          needs with expert solutions that keep your business
                          running smoothly and securely.
                        </p>
                        <p>
                          Whether you're streamlining processes or integrating
                          new technologies, our team provides custom services to
                          help you innovate and stay ahead in the digital
                          landscape.
                        </p>
                        <div
                          className="d-flex mainicons_div about-get-in-touch"
                          data-toggle="modal"
                          data-target="#myModal"
                        >
                          <p>Get in Touch</p>
                          <span>
                            <IoMdCall />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="footer_second_main">
                        <span className="footer_head">Web3 solutions</span>
                        <span className="border_services"></span>
                        <div className="footer_links_div">
                          <Link to="">
                            <span className="footer_links_span">
                              Web3 solutions
                            </span>
                          </Link>
                          <Link to="">
                            <span className="footer_links_span">
                              Metaverse development company
                            </span>
                          </Link>
                        </div>
                        <span className="footer_head">
                          Software development
                        </span>
                        <span className="border_services"></span>
                        <div className="footer_links_div">
                          <Link to="">
                            <span className="footer_links_span">
                              Nearshore software development
                            </span>
                          </Link>
                          <Link to="">
                            <span className="footer_links_span">
                              Agile software development
                            </span>
                          </Link>
                          <Link to="">
                            <span className="footer_links_span">
                              Custom software development{" "}
                            </span>
                          </Link>
                          <Link to="">
                            <span className="footer_links_span">
                              Offshore development service
                            </span>
                          </Link>
                          <Link to="">
                            <span className="footer_links_span">
                              CRM development
                            </span>
                          </Link>
                          <Link to="">
                            <span className="footer_links_span">
                              ERP software development
                            </span>
                          </Link>
                        </div>
                        <span className="footer_head">Forex Trading</span>
                        <span className="border_services"></span>
                        <div className="footer_links_div">
                          <Link to="">
                            <span className="footer_links_span">
                              Forex Trading Development
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="footer_second_main">
                        <span className="footer_head">Web app development</span>
                        <span className="border_services"></span>
                        <div className="footer_links_div">
                          <Link to="">
                            <span className="footer_links_span">
                              Web app Development
                            </span>
                          </Link>
                        </div>
                        <span className="footer_head">
                          Mobile app development
                        </span>
                        <span className="border_services"></span>
                        <div className="footer_links_div">
                          <Link to="">
                            <span className="footer_links_span">
                              IOS app development
                            </span>
                          </Link>
                          <Link to="">
                            <span className="footer_links_span">
                              Android app development
                            </span>
                          </Link>
                          <Link to="">
                            <span className="footer_links_span">
                              Progressive app development
                            </span>
                          </Link>
                        </div>
                        {/* <span className="footer_head">Web app development</span> */}
                        <div className="footer_links_div">
                          <span className="border_services"></span>
                          <Link to="">
                            <span className="footer_links_span">Branding</span>
                          </Link>
                          <Link to="">
                            <span className="footer_links_span">SEO & SMM</span>
                          </Link>
                          <Link to="">
                            <span className="footer_links_span">
                              Content marketing
                            </span>
                          </Link>
                          <Link to="">
                            <span className="footer_links_span">
                              ICO Marketing
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="projects"
                className={`container-fluid section-three  ${
                  activeSections.section3 ? "active_class" : "nonactive_class"
                }`}
              >
                <div className="row m-0">
                  <div className="col-lg-3  col-md-5">
                    <div className="projects-sidebar">
                      <div className="getting_card_contentPro">
                        <span className="getcard_sub_subhead padding-right">
                          A quick glimpse of our.
                        </span>
                        <h1>Projects</h1>
                      </div>
                      <div
                        className="getting_card_contentNew"
                        style={{ height: "100%" }}
                      >
                        <p>Categories</p>
                        <span className="border_services"></span>
                        <div className="d-flex product_menu mt-4">
                          <div className="exchange_text">
                            <p onClick={() => scrollToImage("Cryto Exchange")}>
                              Centralized Exchange
                            </p>
                            <span>
                              <i class="fa-solid fa-arrow-right"></i>
                            </span>
                          </div>
                        </div>
                        <div className="d-flex product_menu">
                          <div className="exchange_text" id="scrollname1">
                            <p onClick={() => scrollToImage("Meme Coin")}>
                              Meme Coin
                            </p>
                            <span>
                              <i class="fa-solid fa-arrow-right"></i>
                            </span>
                          </div>
                        </div>
                        <div className="d-flex product_menu">
                          <div className="exchange_text">
                            <p onClick={() => scrollToImage("ICO")}>ICO</p>
                            <span>
                              <i class="fa-solid fa-arrow-right"></i>
                            </span>
                          </div>
                        </div>
                        <div className="d-flex product_menu">
                          <div className="exchange_text">
                            <p onClick={() => scrollToImage("Mobile App")}>
                              Mobile App
                            </p>
                            <span>
                              <i class="fa-solid fa-arrow-right"></i>
                            </span>
                          </div>
                        </div>

                        <div className="d-flex product_menu">
                          <div className="exchange_text">
                            <p onClick={() => scrollToImage("Gaming")}>
                              Gaming
                            </p>
                            <span>
                              <i class="fa-solid fa-arrow-right"></i>
                            </span>
                          </div>
                        </div>
                        <div className="d-flex product_menu">
                          <div className="exchange_text">
                            <p onClick={() => scrollToImage("Bot")}>Bot</p>
                            {/* <span><FaArrowRight/></span> */}
                            <span>
                              <i class="fa-solid fa-arrow-right"></i>
                            </span>
                          </div>
                        </div>

                        <div className="d-flex product_menu">
                          <div className="exchange_text">
                            <p onClick={() => scrollToImage("MLM")}>MLM</p>
                            <span>
                              <i class="fa-solid fa-arrow-right"></i>
                            </span>
                          </div>
                          {/* </div> */}
                        </div>
                        <div className="d-flex product_menu">
                          <div className="exchange_text">
                            <p onClick={() => scrollToImage("Crypto Payment")}>
                              Crypto Payment
                            </p>
                            <span>
                              <i class="fa-solid fa-arrow-right"></i>
                            </span>
                          </div>
                        </div>
                        <div className="d-flex product_menu">
                          <div className="exchange_text">
                            <p onClick={() => scrollToImage("NFT")}>NFT</p>
                            <span>
                              <i class="fa-solid fa-arrow-right"></i>
                            </span>
                          </div>
                        </div>

                        <div className="d-flex product_menu">
                          <div className="exchange_text">
                            <p
                              onClick={() =>
                                scrollToImage("Decentralized Exchange & Defi")
                              }
                            >
                              Decentralized Exchange & Defi
                            </p>
                            <span>
                              <i class="fa-solid fa-arrow-right"></i>
                            </span>
                          </div>
                        </div>
                        <div className="d-flex product_menu">
                          <div className="exchange_text">
                            <p onClick={() => scrollToImage("Own BlockChain")}>
                              Own Blockchain
                            </p>
                            <span>
                              <i class="fa-solid fa-arrow-right"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-8 col-md-7 aboutus_header product_row_Scroll">
                    <div className="row project-card-row">
                      <div className="col-lg-6 project-card-col">
                        <div className="card_product">
                          <div className="img_div" ref={refs["Meme Coin"]}>
                            <img
                              className="normal_imageant"
                              src={ANTIWarTNimg}
                              alt="...Loading"
                            />
                          </div>
                          <p>Anti Israel War</p>
                        </div>
                      </div>
                      <div className="col-lg-6 project-card-col">
                        <div className="card_product">
                          <div className="img_div">
                            <img
                              className="normal_imagesckn"
                              src={scknimg}
                              alt="...Loading"
                            />
                          </div>
                          <p>Screaming Chicken</p>
                        </div>
                      </div>
                      <div className="col-lg-6 project-card-col">
                        <div className="card_product">
                          <div className="img_div">
                            <img
                              className="normal_imagemonk"
                              src={MitcomeThumbnailimg}
                              alt="...Loading"
                            />
                          </div>
                          <p>Mitcome</p>
                        </div>
                      </div>
                      <div className="col-lg-6 project-card-col">
                        <div className="card_product">
                          <div className="img_div">
                            <img
                              className="normal_imagerexi"
                              src={REXITNimg}
                              alt="...Loading"
                            />
                          </div>
                          <p>Reksy</p>
                        </div>
                      </div>
                      <div className="col-lg-6 project-card-col">
                        <div className="card_product">
                          <div className="img_div">
                            <img
                              className="normal_imagetttn"
                              src={TTTTNimg}
                              alt="...Loading"
                            />
                          </div>
                          <p>TTT</p>
                        </div>
                      </div>
                      <div className="col-lg-6 project-card-col">
                        <div className="card_product">
                          <div className="img_div" ref={refs["Gaming"]}>
                            <img
                              className="normal_imagethubbet"
                              src={HupBetTNimg}
                              alt="...Loading"
                            />
                          </div>
                          <p>Hop Bet</p>
                        </div>
                      </div>
                      <div className="col-lg-6 project-card-col">
                        <div className="card_product">
                          <div className="img_div">
                            <img
                              className="normal_imageticgame"
                              src={icGAMEtNimg}
                              alt="...Loading"
                            />
                          </div>
                          <p>IC.Game</p>
                        </div>
                      </div>
                      <div className="col-lg-6 project-card-col">
                        <div className="card_product">
                          <div className="img_div" ref={refs["MLM"]}>
                            <img
                              className="normal_imageticmlm"
                              src={WXDLTnimg}
                              alt="...Loading"
                            />
                          </div>
                          <p>WXDL</p>
                        </div>
                      </div>
                      <div className="col-lg-6 project-card-col">
                        <div className="card_product">
                          <div className="img_div" ref={refs["Bot"]}>
                            <img
                              className="normal_imageticcmbot"
                              src={WYScaleTnimg}
                              alt="...Loading"
                            />
                          </div>
                          <p>Wy Scale</p>
                        </div>
                      </div>
                      <div className="col-lg-6 project-card-col">
                        <div className="card_product">
                          <div className="img_div">
                            <img
                              className="normal_imageticmvbot"
                              src={MevBotTnimg}
                              alt="...Loading"
                            />
                          </div>
                          <p>MEV Bot</p>
                        </div>
                      </div>
                      <div className="col-lg-6 project-card-col">
                        <div className="card_product">
                          <div className="img_div">
                            <img
                              className="normal_imageticbotone"
                              src={Enzothumbnailimg}
                              alt="...Loading"
                            />
                          </div>
                          <p>Enzo</p>
                        </div>
                      </div>
                      <div className="col-lg-6 project-card-col">
                        <div className="card_product">
                          <div className="img_div">
                            <img
                              className="normal_imageticbottwo"
                              src={DahaulBottnimg}
                              alt="...Loading"
                            />
                          </div>
                          <p>Dalauh Holdings</p>
                        </div>
                      </div>
                      <div className="col-lg-6 project-card-col">
                        <div className="card_product">
                          <div className="img_div">
                            <img
                              className="normal_imageticbotthre"
                              src={FlashLoanTnimg}
                              alt="...Loading"
                            />
                          </div>
                          <p>Flash Loan Bot</p>
                        </div>
                      </div>
                      <div className="col-lg-6 project-card-col">
                        <div className="card_product">
                          <div className="img_div">
                            <img
                              className="normal_imageticbotfour"
                              src={MarketMakingBotTimg}
                              alt="...Loading"
                            />
                          </div>
                          <p>Market Making Bot</p>
                        </div>
                      </div>
                      <div className="col-lg-6 project-card-col">
                        <div className="card_product">
                          <div className="img_div" ref={refs["ICO"]}>
                            <img
                              className="normal_imageticicoone"
                              src={BabyWalrumNimg}
                              alt="...Loading"
                            />
                          </div>
                          <p>Baby Walrus</p>
                        </div>
                      </div>
                      <div className="col-lg-6 project-card-col">
                        <div className="card_product">
                          <div className="img_div">
                            <img
                              className="normal_imageticicotwo"
                              src={ZurumiTNimg}
                              alt="...Loading"
                            />
                          </div>
                          <p>Zurumi</p>
                        </div>
                      </div>
                      <div className="col-lg-6 project-card-col">
                        <div className="card_product">
                          <div className="img_div">
                            <img
                              className="normal_imageticicothree"
                              src={VoltrixICOTNimg}
                              alt="...Loading"
                            />
                          </div>
                          <p>Voltrix</p>
                        </div>
                      </div>
                      <div
                        className="col-lg-6 project-card-col"
                        ref={refs["Cryto Exchange"]}
                      >
                        <div className="card_product">
                          <div className="img_div">
                            <img
                              className="normal_imageticicryex"
                              src={FidexTNimg}
                              alt="...Loading"
                            />
                          </div>
                          <p>Fidex</p>
                        </div>
                      </div>
                      <div className="col-lg-6 project-card-col">
                        <div className="card_product">
                          <div className="img_div">
                            <img
                              className="normal_imageticicryone"
                              src={VoltrixExchangeimg}
                              alt="...Loading"
                            />
                          </div>
                          <p>Voltrix Crypt</p>
                        </div>
                      </div>
                      <div className="col-lg-6 project-card-col">
                        <div className="card_product">
                          <div className="img_div">
                            <img
                              className="normal_imageticicrybit"
                              src={BITBuyerxTNimg}
                              alt="...Loading"
                            />
                          </div>
                          <p>Bit Buyrex</p>
                        </div>
                      </div>
                      <div className="col-lg-6 project-card-col">
                        <div className="card_product">
                          <div className="img_div" ref={refs["Mobile App"]}>
                            <img
                              className="normal_imageticicrytwo"
                              src={VoltriAppnimg}
                              alt="...Loading"
                            />
                          </div>
                          <p>Voltrix Crypt</p>
                        </div>
                      </div>
                      <div className="col-lg-6 project-card-col">
                        <div className="card_product">
                          <div className="img_div">
                            <img
                              className="normal_imageticiapp"
                              src={Teleminigameimg}
                              alt="...Loading"
                            />
                          </div>
                          <p>Tele Mini Game</p>
                        </div>
                      </div>
                      <div className="col-lg-6 project-card-col">
                        <div className="card_product">
                          <div className="img_div">
                            <img
                              className="normal_imageticiappone"
                              src={BitgetAppTnimg}
                              alt="...Loading"
                            />
                          </div>
                          <p>Nexus Pro</p>
                        </div>
                      </div>
                      <div className="col-lg-6 project-card-col">
                        <div className="card_product">
                          <div className="img_div">
                            <img
                              className="normal_imageticiapptwo"
                              src={TrustPeapptnimg}
                              alt="...Loading"
                            />
                          </div>
                          <p>Trust Pe</p>
                        </div>
                      </div>
                      <div className="col-lg-6 project-card-col">
                        <div className="card_product">
                          <div className="img_div">
                            <img
                              className="normal_imageticiappthre"
                              src={FidexApptnimg}
                              alt="...Loading"
                            />
                          </div>
                          <p>Fidex</p>
                        </div>
                      </div>
                      <div className="col-lg-6 project-card-col">
                        <div className="card_product">
                          <div className="img_div" ref={refs["Own BlockChain"]}>
                            <img
                              className="normal_imageticiblockcha"
                              src={OwnBlockchainimg}
                              alt="...Loading"
                            />
                          </div>
                          <p>Maalscan</p>
                        </div>
                      </div>
                      <div className="col-lg-6 project-card-col">
                        <div className="card_product">
                          <div
                            className="img_div"
                            ref={refs["Decentralized Exchange & Defi"]}
                          >
                            <img
                              className="normal_imageticidefione"
                              src={keedeximg}
                              alt="...Loading"
                            />
                          </div>
                          <p>Keedex</p>
                        </div>
                      </div>
                      <div className="col-lg-6 project-card-col">
                        <div className="card_product">
                          <div className="img_div">
                            <img
                              className="normal_imageticidefitwo"
                              src={Yarlooimg}
                              alt="...Loading"
                            />
                          </div>
                          <p>Yarloo</p>
                        </div>
                      </div>
                      <div className="col-lg-6 project-card-col">
                        <div className="card_product">
                          <div className="img_div" ref={refs["NFT"]}>
                            <img
                              className="normal_imageticinft"
                              src={nftimg}
                              alt="...Loading"
                            />
                          </div>
                          <p>NFT</p>
                        </div>
                      </div>
                      <div className="col-lg-6 project-card-col">
                        <div className="card_product">
                          <div className="img_div">
                            <img
                              className="normal_imageticinftone"
                              src={nftimgimg}
                              alt="...Loading"
                            />
                          </div>
                          <p>Xverse</p>
                        </div>
                      </div>
                      <div className="col-lg-6 project-card-col">
                        <div className="card_product">
                          <div className="img_div">
                            <img
                              className="normal_imageticinfttwo"
                              src={nft3img}
                              alt="...Loading"
                            />
                          </div>
                          <p>Fantically</p>
                        </div>
                      </div>
                      <div className="col-lg-6 project-card-col">
                        <div className="card_product">
                          <div className="img_div">
                            <img
                              className="normal_imageticinftthree"
                              src={LoudNFtTnimg}
                              alt="...Loading"
                            />
                          </div>
                          <p>loud</p>
                        </div>
                      </div>
                      <div className="col-lg-6 project-card-col">
                        <div className="card_product card_product-mb">
                          <div className="img_div" ref={refs["Crypto Payment"]}>
                            <img
                              className="normal_imageticinftpay"
                              src={paymentgatewaytnimg}
                              alt="...Loading"
                            />
                          </div>
                          <p>Cryto Payment</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="contact"
                className={`container-fluid section-four  text-white section_main contact-pr-0 ${
                  activeSections.section4 ? "active_class" : "nonactive_class"
                }`}
              >
                <div className="row NewHome_main" style={{ height: "100%" }}>
                  <div className="col-lg-10 NewHome-main-col">
                    <div
                      className="getting_card_content"
                      style={{ paddingBottom: "70px" }}
                    >
                      <span className="getcard_sub_subhead padding-right">
                        How to contact us.
                      </span>
                      <h1>Contacts</h1>
                    </div>
                    <div className="contact_formmain">
                      <form>
                        <div className="row m-0">
                          <div className="col-lg-12 form-container">
                            <input
                              type="text"
                              // name="name"
                              placeholder="Enter your name"
                              className="styled-input"
                              name="newformuserName"
                              autoComplete="off"
                              value={newformuserName}
                              max="50"
                              maxLength="50"
                              onChange={handleNewFormChange}
                              onBlur={(e) => handleKeyPress(e, 50, "text")}
                            />
                            {newformuserNameErrref.current &&
                            newformuserNameErrref.current === true ? (
                              <div className="text-danger mt-3">
                                {" "}
                                {validationErrref.current.newformuserName}{" "}
                              </div>
                            ) : (
                              ""
                            )}
                            {/* {errors.name && <span>{errors.name}</span>} */}
                          </div>
                          {/* styled-input */}
                          <div className="col-lg-6 form-container">
                            <PhoneInput
                              country={"us"}
                              value={NewFormMobileValue}
                              inputProps={{
                                name: "phonenumber",
                                required: true,
                              }}
                              autoComplete="off"
                              className="form-phone landing__form-input margin-bottom-land contact-us-input contact-us-phone"
                              onChange={(
                                value,
                                countryObj,
                                e,
                                formattedValue
                              ) =>
                                handleNewFormMobile(
                                  value,
                                  countryObj,
                                  e,
                                  formattedValue
                                )
                              }
                            />
                            {newformmobileErrref.current &&
                            newformmobileErrref.current === true ? (
                              <div className="text-danger mt-3">
                                {validationErrref.current.newformmobile}
                              </div>
                            ) : (
                              ""
                            )}
                            {/* {errors.email && <span>{errors.email}</span>} */}
                          </div>
                          <div className="col-lg-6 form-container text-left form-pl-0">
                            <div className="form-contact-medium contact-us-input mt-2 contact-med-flex">
                              <button
                                type="button"
                                className="dropdown-toggle landing__form-dropdown"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                {selectedPlatform2 == "whatsApp" ? (
                                  <i className="fa-brands fa-whatsapp"></i>
                                ) : selectedPlatform2 == "telegram" ? (
                                  <i className="fa-brands fa-telegram"></i>
                                ) : selectedPlatform2 == "skype" ? (
                                  <i className="fa-brands fa-skype"></i>
                                ) : selectedPlatform2 == "email" ? (
                                  <i
                                    className="fa fa-envelope"
                                    aria-hidden="true"
                                  ></i>
                                ) : (
                                  ""
                                )}
                              </button>
                              <div className="dropdown-menu landing__form-drop-menu">
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={(e) =>
                                    handlePlatformSelect(e, "whatsApp")
                                  }
                                >
                                  <i className="fa-brands fa-whatsapp"></i>
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={(e) =>
                                    handlePlatformSelect(e, "telegram")
                                  }
                                >
                                  <i className="fa-brands fa-telegram"></i>
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={(e) =>
                                    handlePlatformSelect(e, "skype")
                                  }
                                >
                                  <i className="fa-brands fa-skype"></i>
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={(e) =>
                                    handlePlatformSelect(e, "email")
                                  }
                                >
                                  <i
                                    className="fa fa-envelope"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>

                              {selectedPlatform2 && (
                                <input
                                  type={
                                    selectedPlatform2 === "whatsApp"
                                      ? "number"
                                      : "text"
                                  }
                                  className="landing__contact-medium-input contact-medium-width"
                                  placeholder={`Enter your ${selectedPlatform2} ${
                                    selectedPlatform2 == "email"
                                      ? "Address"
                                      : selectedPlatform2 === "whatsApp"
                                      ? "number"
                                      : "number/ID"
                                  }`}
                                  maxLength={
                                    selectedPlatform2 === "email"
                                      ? 250
                                      : selectedPlatform2 === "whatsApp"
                                      ? 15
                                      : 32
                                  }
                                  value={platformInputref.current}
                                  autoComplete="off"
                                  onChange={handlePlatformInputChange}
                                  onBlur={(e) =>
                                    handleKeyPress(
                                      e,
                                      selectedPlatform2 === "email"
                                        ? 250
                                        : selectedPlatform2 === "whatsApp"
                                        ? 15
                                        : 32,
                                      selectedPlatform2 === "email"
                                        ? "email"
                                        : selectedPlatform2 === "whatsApp"
                                        ? "number"
                                        : "text"
                                    )
                                  }
                                />
                              )}
                            </div>
                            {newformmediumValueErrref.current &&
                            newformmediumValueErrref.current === true ? (
                              <span className="text-danger mt-3">
                                {validationErrref.current.newformmediumValue}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-lg-12 form-container">
                            <textarea
                              // name="message"
                              className="contact-textarea"
                              placeholder="Enter your message"
                              // value={formData.message}
                              // className="styled-input"
                              // onChange={handleChange}
                              name="newformmessage"
                              value={newformmessage}
                              max="250"
                              maxLength="250"
                              autoComplete="off"
                              onChange={handleNewFormChange}
                              onBlur={(e) => handleKeyPress(e, 250, "text")}
                            />
                            {newformmessageErrref.current == true ? (
                              <div className="text-danger mt-3">
                                {validationErrref.current.newformmessage}
                              </div>
                            ) : (
                              ""
                            )}
                            {/* {errors.message && <span>{errors.message}</span>} */}
                          </div>

                          <div className="submit_mainbtn">
                            {buttonLoader == true ? (
                              <button className="submit_btn">
                                Loading...{" "}
                              </button>
                            ) : (
                              <button
                                className="submit_btn"
                                onClick={sendServiceContact}
                              >
                                Submit{" "}
                              </button>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div id="myModal" class="modal fade contact_model" role="dialog">
                <div class="modal-dialog modal-md whole_popupmodal">
                  <div class="modal-content service-modal-content">
                    <div>
                      <div className="landing__contact--form hero-modal-contact">
                        <form className="p-0">
                          <h4 className="landing__contact--form-title contact-us-title d-flex justify-content-between align-items-center">
                            Your Details
                            <button
                              type="button"
                              class="service-modal-btn"
                              data-dismiss="modal"
                              onClick={cancelpopup}
                            >
                              ×
                            </button>
                          </h4>
                          <div className="mt-4">
                            <label
                              htmlFor="form-name"
                              className="contact-us-label"
                            >
                              Name
                            </label>{" "}
                            <br />
                            <input
                              type="text"
                              id="form-name"
                              placeholder="Enter your name"
                              className="landing__form-input margin-bottom-land contact-us-input"
                              name="newformuserName"
                              autoComplete="off"
                              value={newformuserName}
                              max="50"
                              maxLength="50"
                              onChange={handleNewFormChange}
                              onBlur={(e) => handleKeyPress(e, 50, "text")}
                            />
                            {newformuserNameErrref.current &&
                            newformuserNameErrref.current === true ? (
                              <div className="text-danger mt-3">
                                {" "}
                                {validationErrref.current.newformuserName}{" "}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="mt-4">
                            <label
                              htmlFor="mobile"
                              className="contact-us-label"
                            >
                              Mobile Number
                            </label>{" "}
                            <br />
                            <PhoneInput
                              country={"us"}
                              value={NewFormMobileValue}
                              inputProps={{
                                name: "phonenumber",
                                required: true,
                              }}
                              autoComplete="off"
                              className="form-phone landing__form-input margin-bottom-land contact-us-input contact-us-phone"
                              onChange={(
                                value,
                                countryObj,
                                e,
                                formattedValue
                              ) =>
                                handleNewFormMobile(
                                  value,
                                  countryObj,
                                  e,
                                  formattedValue
                                )
                              }
                            />
                            {newformmobileErrref.current &&
                            newformmobileErrref.current === true ? (
                              <div className="text-danger mt-3">
                                {validationErrref.current.newformmobile}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="mt-4">
                            <label
                              htmlFor="form-contact"
                              className="contact-us-label"
                            >
                              Contact Medium
                            </label>{" "}
                            <br />
                            <div className="form-contact-medium contact-us-input">
                              <button
                                type="button"
                                className="dropdown-toggle landing__form-dropdown"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                {selectedPlatform2 == "whatsApp" ? (
                                  <i className="fa-brands fa-whatsapp"></i>
                                ) : selectedPlatform2 == "telegram" ? (
                                  <i className="fa-brands fa-telegram"></i>
                                ) : selectedPlatform2 == "skype" ? (
                                  <i className="fa-brands fa-skype"></i>
                                ) : selectedPlatform2 == "email" ? (
                                  <i
                                    className="fa fa-envelope"
                                    aria-hidden="true"
                                  ></i>
                                ) : (
                                  ""
                                )}
                              </button>
                              <div className="dropdown-menu landing__form-drop-menu">
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={(e) =>
                                    handlePlatformSelect(e, "whatsApp")
                                  }
                                >
                                  <i className="fa-brands fa-whatsapp"></i>
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={(e) =>
                                    handlePlatformSelect(e, "telegram")
                                  }
                                >
                                  <i className="fa-brands fa-telegram"></i>
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={(e) =>
                                    handlePlatformSelect(e, "skype")
                                  }
                                >
                                  <i className="fa-brands fa-skype"></i>
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={(e) =>
                                    handlePlatformSelect(e, "email")
                                  }
                                >
                                  <i
                                    className="fa fa-envelope"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>

                              {selectedPlatform2 && (
                                <input
                                  type={
                                    selectedPlatform2 === "whatsApp"
                                      ? "number"
                                      : "text"
                                  }
                                  className="landing__contact-medium-input"
                                  placeholder={`Enter your ${selectedPlatform2} ${
                                    selectedPlatform2 == "email"
                                      ? "Address"
                                      : selectedPlatform2 === "whatsApp"
                                      ? "number"
                                      : "number / ID"
                                  }`}
                                  maxLength={
                                    selectedPlatform2 === "email"
                                      ? 250
                                      : selectedPlatform2 === "whatsApp"
                                      ? 15
                                      : 32
                                  }
                                  value={platformInputref.current}
                                  autoComplete="off"
                                  onChange={handlePlatformInputChange}
                                  onBlur={(e) =>
                                    handleKeyPress(
                                      e,
                                      selectedPlatform2 === "email"
                                        ? 250
                                        : selectedPlatform2 === "whatsApp"
                                        ? 15
                                        : 32,
                                      selectedPlatform2 === "email"
                                        ? "email"
                                        : selectedPlatform2 === "whatsApp"
                                        ? "number"
                                        : "text"
                                    )
                                  }
                                />
                              )}
                            </div>
                            {newformmediumValueErrref.current &&
                            newformmediumValueErrref.current === true ? (
                              <span className="text-danger mt-3">
                                {validationErrref.current.newformmediumValue}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="mt-4">
                            <label
                              htmlFor="message"
                              className="contact-us-label"
                            >
                              Tell Us About Your Project
                            </label>
                            <br />
                            <textarea
                              id="message"
                              placeholder="Message"
                              className="landing__form-input landing-textarea landing__form-input margin-bottom-land contact-us-input"
                              rows="5"
                              name="newformmessage"
                              value={newformmessage}
                              max="250"
                              maxLength="250"
                              autoComplete="off"
                              onChange={handleNewFormChange}
                              onBlur={(e) => handleKeyPress(e, 250, "text")}
                            ></textarea>

                            {newformmessageErrref.current == true ? (
                              <div className="text-danger mt-3">
                                {validationErrref.current.newformmessage}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="text-center mt-4">
                            {buttonLoader == true ? (
                              <button
                                className="primary-landing-main-btn"
                                disabled
                              >
                                loading....
                                <span className="ml-2 right-arr">
                                  <i className="fa-solid fa-arrow-right"></i>
                                </span>
                              </button>
                            ) : (
                              <button
                                className="primary-landing-main-btn"
                                onClick={sendServiceContact}
                              >
                                Submit
                                <span className="ml-2 right-arr">
                                  <i className="fa-solid fa-arrow-right"></i>
                                </span>
                              </button>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Navigation Bar */}
              <nav
                className={`navbar navbar-expand-sm bg-dark navbar-dark fixed-top ${
                  menuopen === true ? "nav_mainopen" : "nav_main"
                }`}
              >
                <div style={{ height: "100%" }}>
                  <span
                    className={`${
                      menuopen === true ? "navbar_closeicon" : "hidenCloseicon"
                    }`}
                    onClick={handleCloseMet}
                  >
                    <i class="fa-solid fa-xmark"></i>
                  </span>
                  <ul
                    className={`navbar-nav ${
                      menuopen === true ? "nav_main_ul" : "nav_main"
                    }`}
                  >
                    <li
                      className={`nav-item ${
                        activeSection === "about" ? "section1Active" : ""
                      }`}
                      onClick={() => handleActiveSection("about")}
                    >
                      <span
                        className={`after_classecir ${
                          activeSection === "section1" ? "section1Active" : ""
                        }`}
                      ></span>
                      <span
                        className={`before_classecir ${
                          activeSection === "section1" ? "sectionActive" : ""
                        }`}
                      ></span>
                      <a href="#about" className="nav-link nav_link-clr">
                        About beleaf
                      </a>
                    </li>
                    <li
                      className={`nav-item ${
                        activeSection === "services" ? "section1Active" : ""
                      }`}
                      onClick={() => handleActiveSection("services")}
                    >
                      <span
                        className={`after_classecir ${
                          activeSection === "services" ? "section1Active" : ""
                        }`}
                      ></span>
                      <span
                        className={`before_classecir ${
                          activeSection === "services" ? "section1Active" : ""
                        }`}
                      ></span>
                      <a className="nav-link nav_link-clr" href="#services">
                        Our services
                      </a>
                    </li>
                    <li
                      className={`nav-item ${
                        activeSection === "projects" ? "section1Active" : ""
                      }`}
                      onClick={() => handleActiveSection("projects")}
                    >
                      <span
                        className={`after_classecir ${
                          activeSection === "projects" ? "section1Active" : ""
                        }`}
                      ></span>
                      <span
                        className={`before_classecir ${
                          activeSection === "projects" ? "section1Active" : ""
                        }`}
                      ></span>
                      <a className="nav-link nav_link-clr" href="#projects">
                        Projects
                      </a>
                    </li>
                    <li
                      className={`nav-item ${
                        activeSection === "contact" ? "section1Active" : ""
                      }`}
                      onClick={() => handleActiveSection("contact")}
                    >
                      <span
                        className={`after_classecir ${
                          activeSection === "contact" ? "section1Active" : ""
                        }`}
                      ></span>
                      <span
                        className={`before_classecir ${
                          activeSection === "contact" ? "section1Active" : ""
                        }`}
                      ></span>
                      <a className="nav-link nav_link-clr" href="#contact">
                        Contact us &nbsp;
                      </a>
                    </li>
                    <div
                      className={`link_maintag ${
                        menuopen === true ? "link_maintag_menu" : ""
                      }`}
                    >
                      <div
                        className="linkedin_tag"
                        onClick={() =>
                          window.open(
                            "https://www.linkedin.com/company/beleaf-technologies-blockchain-development-company/posts/?feedView=all",
                            "_blank"
                          )
                        }
                      >
                        <span>
                          <FaLinkedinIn />
                        </span>
                        <p>LinkedIn</p>
                      </div>
                      <div
                        className="instagram_tag"
                        onClick={() =>
                          window.open(
                            "https://www.instagram.com/beleaftechnologies/",
                            "_blank"
                          )
                        }
                      >
                        <span>
                          <FaInstagramSquare />
                        </span>
                        <p>Instagram</p>
                      </div>
                    </div>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewHome;
