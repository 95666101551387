import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import NewLanding from "./pages/NewLanding";
import { useEffect } from "react";
import NewHome from "./pages/NewHome";

function App() {
  useEffect(() => {
    if (window.AOS) {
      window.AOS.init({
        duration: 1000,
        easing: "ease-in-out",
        once: true,
      });
    }
  }, []);

  return (
    <>
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          {/* <Route path="/" element={<Landing />} /> */}
          {/* <Route path="/home" element={<Home />} /> */}
          <Route path="/pages" element={<NewHome />} />
          <Route path="/" element={<NewLanding />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
