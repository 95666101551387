import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Logo from "../assets/logo.png";
import { AppBar, Toolbar, Typography } from "@material-ui/core";
import { TbWorld } from "react-icons/tb";

const NewLanding = () => {
  const navigate = useNavigate();

  const navigateHome = () => {
    navigate("/pages");
  };

  const [headerClass, setHeaderClass] = useState("main-header slide-header");
  const [logoClass, setLogoClass] = useState("logo-wrapper logo-slider");
  const [btnClass, setBtnClass] = useState("header-btn landing-header-btn");

  useEffect(() => {
    const cameFromAbout = localStorage.getItem("cameFromAbout");
    if (cameFromAbout === "true") {
      setHeaderClass("main-header");
      setLogoClass("logo-wrapper");
      setBtnClass("header-btn");
      localStorage.removeItem("cameFromAbout");
    }
  }, []);

  return (
    <div className="landing-bg">
      <div className={headerClass}>
        <header className="scrolled header-wrapper">
          <AppBar position="static" className="header_secondsec">
            <Toolbar className="container-fluid p-0 w-100 justify-content-between">
              <Typography variant="h6">
                <div className="d-flex">
                  <Link className={logoClass}>
                    <img src={Logo} className="img-fluid" alt="logo" />
                  </Link>
                </div>
              </Typography>
              <div className="rightside_menu">
                <div>
                  <>
                    <button
                      className={btnClass}
                      onClick={() =>
                        window.open("https://beleaftechnologies.com/", "_blank")
                      }
                    >
                      <span className="web_icon">
                        <TbWorld />
                      </span>
                      <span className="">Website</span>
                    </button>
                  </>
                </div>
              </div>
            </Toolbar>
          </AppBar>
        </header>
      </div>

      <main className="header-wrapper landing-main">
        <h1 className="landing-title">
          Wide range of services for every industry
        </h1>
        <p className="landing-content">
          Beleaf Technologies delivers innovative solutions across industries,
          from blockchain to AI, helping businesses thrive in the digital age.
        </p>

        <div className="explore-btn-wrap">
          <div className="landing-explore-btn" onClick={navigateHome}>
            <i class="fa-solid fa-arrow-down"></i>
          </div>
          <p className="explore-btn-content" onClick={navigateHome}>
            Explore
          </p>
        </div>
      </main>
    </div>
  );
};

export default NewLanding;
